._InputWrapper-module__inputWrapper__3s3nY {
  position: relative;
  display: flex;
  width: auto;
  height: 73px;
  min-height: 73px;
  max-height: 73px;
  flex-wrap: wrap;
  vertical-align: top;
  text-align: left;
  margin-top: 16px;
  margin-bottom: 26px; }
  ._InputWrapper-module__inputWrapper__3s3nY ::-ms-clear {
    display: none; }
  ._InputWrapper-module__inputWrapper__3s3nY label {
    margin-bottom: 0; }

._InputWrapper-module__checkboxWrapper__Y38bD, ._InputWrapper-module__radioWrapper__3a3UQ {
  height: auto;
  min-height: unset;
  max-height: unset; }
  ._InputWrapper-module__checkboxWrapper__Y38bD > div, ._InputWrapper-module__radioWrapper__3a3UQ > div {
    top: 100% !important; }

._InputWrapper-module__error__3tx0f input {
  color: #DD1F4A !important;
  border-color: #DD1F4A; }
  ._InputWrapper-module__error__3tx0f input:hover, ._InputWrapper-module__error__3tx0f input:focus {
    border-color: #DD1F4A; }

._InputWrapper-module__error__3tx0f._InputWrapper-module__checkboxWrapper__Y38bD svg g[stroke="#AFAFB1"],
._InputWrapper-module__error__3tx0f._InputWrapper-module__checkboxWrapper__Y38bD svg g[stroke="#1B8DB3"],
._InputWrapper-module__error__3tx0f._InputWrapper-module__checkboxWrapper__Y38bD svg g[stroke="#145685"] {
  stroke: #DD1F4A !important; }

._InputWrapper-module__warning__GwVHB input {
  color: #E67417 !important;
  border-color: #E67417; }
  ._InputWrapper-module__warning__GwVHB input:hover, ._InputWrapper-module__warning__GwVHB input:focus {
    border-color: #E67417; }

._Label-module__inputLabel__2wwz5 {
  display: block;
  width: 100%;
  border: none;
  color: #969899;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  white-space: normal; }

._Text-module__textInput__3x5Ri {
  outline: none;
  padding: 5px 0;
  display: block;
  width: 100%;
  border: none;
  height: 50px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 0;
  line-height: 16px;
  color: #969899;
  background: transparent;
  letter-spacing: -0.2px;
  border-bottom: 2px solid #969899;
  transition: border-bottom linear 0.2s; }
  ._Text-module__textInput__3x5Ri:hover {
    border-bottom: 2px solid #10446A; }
  ._Text-module__textInput__3x5Ri:focus, ._Text-module__textInput__3x5Ri._Text-module__hasValue__YTYLK {
    color: #242F38; }
  ._Text-module__textInput__3x5Ri:focus, ._Text-module__textInput__3x5Ri:active {
    border-bottom: 2px solid #1B8DB3; }
  ._Text-module__textInput__3x5Ri._Text-module__hasValue__YTYLK {
    text-overflow: ellipsis;
    white-space: nowrap; }
  ._Text-module__textInput__3x5Ri._Text-module__pr20__1ep84 {
    padding-right: 20px !important; }
  ._Text-module__textInput__3x5Ri::-moz-placeholder {
    color: #969899;
    opacity: 1;
    /* Firefox */
    font-size: 16px; }
  ._Text-module__textInput__3x5Ri:-ms-input-placeholder {
    color: #969899;
    opacity: 1;
    /* Firefox */
    font-size: 16px; }
  ._Text-module__textInput__3x5Ri::placeholder {
    color: #969899;
    opacity: 1;
    /* Firefox */
    font-size: 16px; }
  ._Text-module__textInput__3x5Ri._Text-module__disabled__3ne8M {
    color: #D7D8D9;
    border-color: #D7D8D9; }
    ._Text-module__textInput__3x5Ri._Text-module__disabled__3ne8M::-moz-placeholder {
      color: #D7D8D9; }
    ._Text-module__textInput__3x5Ri._Text-module__disabled__3ne8M:-ms-input-placeholder {
      color: #D7D8D9; }
    ._Text-module__textInput__3x5Ri._Text-module__disabled__3ne8M::placeholder {
      color: #D7D8D9; }

._Text-module__textInput__3x5Ri + div {
  opacity: 0;
  position: absolute;
  top: -25px;
  left: -6px;
  width: calc(100% + 12px);
  height: calc(100% + 28px);
  border: 2px solid #10446A;
  border-radius: 4px;
  box-shadow: 0 0 0 4px rgba(71, 136, 177, 0.1);
  pointer-events: none; }

._Text-module__textInput__3x5Ri + ._Text-module__dropdown__2vpEl {
  height: calc(100% + 33px);
  top: -27px; }

._Text-module__textInput__3x5Ri + ._Text-module__filtering__3xzF7,
._Text-module__textInput__3x5Ri + ._Text-module__language__oVLEc {
  top: -6px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 14px); }

._Text-module__textInput__3x5Ri + label {
  left: 0;
  top: 19px;
  padding: 0;
  height: 17px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  position: absolute;
  color: #AFAFB1;
  pointer-events: none;
  transition: 200ms ease all; }

._Text-module__textInputContainer__16X0A {
  width: 100%;
  position: relative; }
  ._Text-module__textInputContainer__16X0A svg {
    position: absolute;
    top: 15px;
    right: 0; }
  ._Text-module__textInputContainer__16X0A._Text-module__disabledContainer__3UiXj ._Text-module__calendar__2WUup rect, ._Text-module__textInputContainer__16X0A._Text-module__disabledContainer__3UiXj ._Text-module__calendar__2WUup path {
    stroke: #D7D8D9; }

._Text-module__textInputContainer__16X0A + ._Text-module__error__1mKD4 {
  color: red; }

._Text-module__greenCheckBox__1ruM9 {
  top: 20px !important; }

._Text-module__greenCheckBoxRight__cQzAJ {
  right: 44px !important; }

._Dropdown-module__dropdown__1WaTk {
  position: absolute;
  cursor: pointer;
  width: 100%;
  bottom: 0; }

._Dropdown-module__hasValue__2yF2M {
  color: #242F38;
  text-overflow: ellipsis;
  white-space: nowrap; }

._Dropdown-module__clear__3cKQK {
  display: block;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%); }

._Dropdown-module__options__3midr {
  position: absolute;
  left: 0;
  height: 0;
  top: calc(100% - 0px);
  width: 100%;
  opacity: 0;
  color: #242F38;
  transition: opacity 200ms;
  overflow-y: auto;
  background: #F3F3F3; }

._Dropdown-module__open__yKu-8 ._Dropdown-module__options__3midr {
  opacity: 1;
  height: auto;
  max-height: 300px;
  z-index: 2; }

._Dropdown-module__open__yKu-8 ._Dropdown-module__arrow__1_WtL svg {
  transform: rotate(180deg); }

._Dropdown-module__arrow__1_WtL {
  position: absolute;
  right: 0;
  top: 50%;
  text-align: right;
  padding: 15px 5px;
  transform: translateY(-50%);
  pointer-events: none; }

._Dropdown-module__arrow__1_WtL path {
  stroke: #969899; }

._Dropdown-module__dropdown__1WaTk._Dropdown-module__disabled__1llYN ._Dropdown-module__arrow__1_WtL path {
  stroke: #D7D8D9; }

._Dropdown-module__dropdown__1WaTk._Dropdown-module__disabled__1llYN ._Dropdown-module__clear__3cKQK path {
  stroke: #D7D8D9; }

._Option-module__option__3Ogsn {
  position: relative;
  color: #242F38;
  padding: 10px 16px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  word-break: break-word;
  letter-spacing: 0.2px;
  border-bottom: 1px solid #E3E5E6; }
  ._Option-module__option__3Ogsn:hover, ._Option-module__option__3Ogsn._Option-module__active__2BhOu {
    background-color: #D0DDE7;
    color: #10446A;
    font-weight: bolder; }

._Checkbox-module__checkbox__3C1an {
  width: 1px;
  height: 1px;
  display: none; }

._Checkbox-module__label__O05M2 {
  display: flex;
  align-items: flex-start;
  position: relative;
  padding: 0;
  cursor: pointer;
  width: auto;
  max-width: 100%;
  white-space: pre-wrap;
  height: auto;
  max-height: 100%;
  color: #242F38;
  font-size: 14px;
  letter-spacing: -0.17px;
  line-height: 1.6rem;
  outline: 0;
  transition: all .2s linear; }
  ._Checkbox-module__label__O05M2:before {
    content: "";
    opacity: 0;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    position: absolute;
    top: -5px;
    left: -5px;
    border-radius: 2px;
    border: 2px solid #145685;
    box-shadow: 0 0 0 4px rgba(20, 86, 133, 0.1); }
  ._Checkbox-module__label__O05M2:focus:before, ._Checkbox-module__label__O05M2:hover:before {
    border: 2px solid #145685; }
  ._Checkbox-module__label__O05M2:focus ._Checkbox-module__checkmark__7D5tk g[stroke="#AFAFB1"], ._Checkbox-module__label__O05M2:hover ._Checkbox-module__checkmark__7D5tk g[stroke="#AFAFB1"] {
    stroke: #145685; }
  ._Checkbox-module__label__O05M2._Checkbox-module__checked__3r-np ._Checkbox-module__checkmark__7D5tk g[stroke="#AFAFB1"] {
    stroke: #1B8DB3; }
  ._Checkbox-module__label__O05M2._Checkbox-module__checked__3r-np:before {
    border: 2px solid #1B8DB3; }

._Checkbox-module__checkmark__7D5tk {
  height: 18px;
  min-height: 18px;
  width: 18px;
  min-width: 18px;
  margin-top: -1px; }
  ._Checkbox-module__checkmark__7D5tk._Checkbox-module__hasLabel__QOzW7 {
    margin-right: 10px; }

._Textarea-module__textareaInput__Be7hz {
  font-family: unset;
  outline: none;
  padding-top: 10px;
  padding-bottom: 5px;
  display: block;
  width: 100%;
  border: none;
  height: 50px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 0;
  line-height: 16px;
  color: var(--main);
  background: transparent;
  letter-spacing: -0.2px;
  border-bottom: 2px solid #AFAFB1;
  transition: border-bottom linear 0.2s; }
  ._Textarea-module__textareaInput__Be7hz:hover {
    border-bottom: 2px solid #10446A; }
  ._Textarea-module__textareaInput__Be7hz:focus, ._Textarea-module__textareaInput__Be7hz._Textarea-module__hasValue__20EqD {
    color: #242F38; }
  ._Textarea-module__textareaInput__Be7hz:focus, ._Textarea-module__textareaInput__Be7hz:active {
    border-bottom: 2px solid #1B8DB3; }
  ._Textarea-module__textareaInput__Be7hz._Textarea-module__hasValue__20EqD {
    color: var(--main);
    text-overflow: ellipsis;
    white-space: nowrap; }

._Textarea-module__textareaInput__Be7hz + div {
  opacity: 0;
  position: absolute;
  top: -30px;
  left: -10px;
  width: calc(100% + 22px);
  height: calc(100% + 36px);
  border: 2px solid black;
  border-radius: 4px;
  box-shadow: 0px 0px 0px 4px rgba(38, 50, 56, 0.1);
  pointer-events: none; }

._Textarea-module__textareaInput__Be7hz + label {
  left: 0;
  top: 19px;
  padding: 0;
  height: 17px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  position: absolute;
  color: #AFAFB1;
  pointer-events: none;
  transition: 200ms ease all; }

._Textarea-module__textareaInputContainer__23KNL {
  width: 100%;
  position: relative; }

._InputFile-module__inputFileWrapper__3iCDA {
  width: 100%;
  height: 48px;
  margin-bottom: 0;
  display: flex;
  background-color: #E3E5E6;
  cursor: pointer; }
  ._InputFile-module__inputFileWrapper__3iCDA ._InputFile-module__inputFileButton__lk8V3 {
    display: block;
    color: #242F38;
    display: inline-block;
    width: 140px;
    font-weight: bold;
    font-size: 12px;
    line-height: 48px;
    text-transform: uppercase;
    height: 48px;
    text-align: center;
    margin-left: 16px; }
  ._InputFile-module__inputFileWrapper__3iCDA ._InputFile-module__inputFileName__2aE1h {
    display: block;
    color: #969899;
    height: 48px;
    width: 232px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.2px;
    line-height: 48px;
    margin-left: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  ._InputFile-module__inputFileWrapper__3iCDA ._InputFile-module__inputFile__2JGN- {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1; }
  ._InputFile-module__inputFileWrapper__3iCDA ._InputFile-module__inputFile__2JGN- + div {
    opacity: 0;
    position: absolute;
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 14px);
    border: 2px solid black;
    border-radius: 4px;
    box-shadow: 0px 0px 0px 4px rgba(38, 50, 56, 0.1);
    pointer-events: none; }
  ._InputFile-module__inputFileWrapper__3iCDA ._InputFile-module__inputFile__2JGN-:focus {
    outline: none; }
  ._InputFile-module__inputFileWrapper__3iCDA ._InputFile-module__hasFile__3ITGD {
    color: #242F38; }

._InputRadio-module__radioWrapper__fucc_ {
  width: 100%;
  display: flex;
  flex-flow: wrap;
  padding: 12px 0 0; }
  ._InputRadio-module__radioWrapper__fucc_:after {
    content: "";
    opacity: 0;
    position: absolute;
    top: -10px;
    left: -12px;
    width: calc(100% + 20px);
    height: calc(100% + 16px);
    border: 2px solid black;
    border-radius: 4px;
    box-shadow: 0px 0px 0px 4px rgba(38, 50, 56, 0.1);
    pointer-events: none; }
  ._InputRadio-module__radioWrapper__fucc_._InputRadio-module__disabled__2tWHB {
    opacity: .6;
    pointer-events: none; }

._InputRadio-module__radioLabel__ls6dD {
  font-size: 13px;
  background-color: #E3E5E6; }

._InputRadio-module__radioInput__2XjY7:checked ~ ._InputRadio-module__radioLabel__ls6dD:before {
  padding: 0;
  background-color: #1B8DB3; }

input._InputRadio-module__radioInput__2XjY7 {
  width: 100%;
  left: 0;
  height: 100%;
  z-index: 10; }

input._InputRadio-module__radioInput__2XjY7:checked ~ ._InputRadio-module__radioLabel__ls6dD:not(._InputRadio-module__input-wrapper__gkHa8) {
  padding: 0;
  box-shadow: inset 0 -2px 0 0 #1B8DB3;
  font-weight: 500; }

input._InputRadio-module__radioInput__2XjY7:hover ~ ._InputRadio-module__radioLabel__ls6dD:not(._InputRadio-module__input-wrapper__gkHa8) {
  color: #10446A;
  font-weight: 600; }

input._InputRadio-module__radioInput__2XjY7 ~ ._InputRadio-module__radioLabel__ls6dD {
  padding: 0;
  text-align: center;
  transition: box-shadow linear 0.2s;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap !important; }

._InputRadio-module__radio__n_aI4 {
  height: 22px;
  flex: 1 1 100px;
  margin-right: 5px;
  margin-bottom: 4px; }
  ._InputRadio-module__radio__n_aI4:first-child {
    margin-left: 0; }

._InputRadio-module__radioLabel__ls6dD {
  background-color: transparent;
  width: 100%;
  height: 100%;
  color: #242F38;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 16px;
  text-align: center;
  line-height: 46px; }

._InputRadio-module__radioWrapper__fucc_ {
  padding: 0;
  text-align: center;
  margin-bottom: -4px; }

._InputRadio-module__radio__n_aI4 {
  position: relative;
  display: inline-block;
  vertical-align: top;
  height: 48px; }

._InputRadio-module__radioLabel__ls6dD {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1px;
  background-color: #F5F5F5;
  cursor: pointer; }

input._InputRadio-module__radioInput__2XjY7 {
  position: absolute;
  opacity: 0;
  cursor: pointer; }

input._InputRadio-module__radioInput__2XjY7:checked ~ ._InputRadio-module__radioLabel__ls6dD {
  padding: 0; }

._CircularRadio-module__radio__3kgWS {
  width: 1px;
  height: 1px;
  display: none; }

._CircularRadio-module__label__8JDhN {
  display: flex;
  align-items: flex-start;
  position: relative;
  padding: 0;
  cursor: pointer;
  width: auto;
  max-width: 100%;
  white-space: pre-wrap;
  height: auto;
  max-height: 100%;
  color: #242F38;
  font-size: 14px;
  letter-spacing: -0.17px;
  line-height: 1.6rem;
  outline: 0;
  transition: all .2s linear; }
  ._CircularRadio-module__label__8JDhN:before {
    content: "";
    opacity: 0;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    position: absolute;
    top: -5px;
    left: -5px;
    border-radius: 2px;
    border: 2px solid #145685;
    box-shadow: 0 0 0 4px rgba(20, 86, 133, 0.1); }
  ._CircularRadio-module__label__8JDhN:focus ._CircularRadio-module__circle__2MESP, ._CircularRadio-module__label__8JDhN:hover ._CircularRadio-module__circle__2MESP {
    border-color: #145685; }
  ._CircularRadio-module__label__8JDhN._CircularRadio-module__checked__1Ll7r ._CircularRadio-module__circle__2MESP {
    border-color: #1B8DB3; }
    ._CircularRadio-module__label__8JDhN._CircularRadio-module__checked__1Ll7r ._CircularRadio-module__circle__2MESP ._CircularRadio-module__innerCircle__gXoEC {
      opacity: 1; }
  ._CircularRadio-module__label__8JDhN._CircularRadio-module__checked__1Ll7r:before {
    border: 2px solid #1B8DB3; }

._CircularRadio-module__circle__2MESP {
  position: relative;
  height: 18px;
  min-height: 18px;
  width: 18px;
  min-width: 18px;
  margin-top: -1px;
  border: 2px solid #969899;
  border-radius: 9px; }
  ._CircularRadio-module__circle__2MESP._CircularRadio-module__hasLabel__16Va7 {
    margin-right: 10px; }
  ._CircularRadio-module__circle__2MESP ._CircularRadio-module__innerCircle__gXoEC {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 5px;
    opacity: 0;
    background-color: #1B8DB3; }

._Calendar-module__calendar__sm-a0 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }

._Calendar-module__calendar__sm-a0 ._Calendar-module__calendarTitle__1ujpj {
  font-size: 22px;
  line-height: 22px;
  color: var(--grey-dark);
  margin-bottom: 40px; }

._Calendar-module__calendar__sm-a0 ._Calendar-module__datePicker__1l7s6 {
  width: 100%;
  border-radius: 8px;
  position: relative; }

._Calendar-module__calendar__sm-a0 ._Calendar-module__datePicker__1l7s6 ._Calendar-module__choseDate__1b6G3 {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 9px 60px -15px rgba(38, 50, 56, 0.25);
  position: absolute;
  top: 53px;
  width: 100%;
  z-index: 9999;
  height: 0;
  border-radius: 0 0 .8rem .8rem; }
  ._Calendar-module__calendar__sm-a0 ._Calendar-module__datePicker__1l7s6 ._Calendar-module__choseDate__1b6G3._Calendar-module__isOpened__3QTTv {
    height: 35rem; }

._Calendar-module__clue__1qf8w {
  color: #969899;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  padding: 2rem; }

._Calendar-module__calendar__sm-a0 ._Calendar-module__datePicker__1l7s6 ._Calendar-module__date__3t_Pc {
  box-sizing: border-box;
  display: flex;
  height: 50px;
  align-items: center;
  border-bottom: 2px solid #969899;
  transition: border-bottom linear 0.2s; }

._Calendar-module__dateParameter__1G9j_ {
  color: #242F38;
  font-size: 16px;
  letter-spacing: -0.2px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
  padding: 0; }
  ._Calendar-module__dateParameter__1G9j_._Calendar-module__dateParameterDate__3fTym {
    width: 20px; }
  ._Calendar-module__dateParameter__1G9j_._Calendar-module__dateParameterMonth__7VAA5 {
    width: 28px; }
  ._Calendar-module__dateParameter__1G9j_._Calendar-module__dateParameterYear__2a6oo {
    width: 42px; }
  ._Calendar-module__dateParameter__1G9j_._Calendar-module__point__11asg {
    width: auto;
    margin: 0 1px; }

._Calendar-module__test__HBOv0[placeholder]:empty:before {
  content: attr(placeholder);
  color: var(--main); }

._Calendar-module__activeDateParameter__2BK7s::-moz-placeholder {
  color: #1B8DB3; }

._Calendar-module__activeDateParameter__2BK7s:-ms-input-placeholder {
  color: #1B8DB3; }

._Calendar-module__activeDateParameter__2BK7s,
._Calendar-module__activeDateParameter__2BK7s::placeholder {
  color: #1B8DB3; }

._Calendar-module__calendar__sm-a0 ._Calendar-module__datePicker__1l7s6 ._Calendar-module__confirmationDialog__2_iUn {
  box-sizing: border-box;
  width: 100%;
  padding: 24px 162px 48px;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 896px) {
    ._Calendar-module__calendar__sm-a0 ._Calendar-module__datePicker__1l7s6 ._Calendar-module__confirmationDialog__2_iUn {
      padding: 24px 24px 48px; } }

._Calendar-module__calendar__sm-a0 ._Calendar-module__button__1URM5 {
  width: 151px;
  padding: 19px 48px;
  margin: 0; }

._Calendar-module__calendarIcon__2JTLK {
  position: absolute;
  top: 15px;
  right: 0;
  cursor: pointer; }

._Calendar-module__error__Qa3Y6 {
  border-bottom: 2px solid #DD1F4A !important; }
  ._Calendar-module__error__Qa3Y6 ._Calendar-module__dateParameter__1G9j_ {
    color: #DD1F4A; }
    ._Calendar-module__error__Qa3Y6 ._Calendar-module__dateParameter__1G9j_::-moz-placeholder {
      color: #DD1F4A; }
    ._Calendar-module__error__Qa3Y6 ._Calendar-module__dateParameter__1G9j_:-ms-input-placeholder {
      color: #DD1F4A; }
    ._Calendar-module__error__Qa3Y6 ._Calendar-module__dateParameter__1G9j_::placeholder {
      color: #DD1F4A; }
  ._Calendar-module__error__Qa3Y6 ._Calendar-module__calendarIcon__2JTLK rect, ._Calendar-module__error__Qa3Y6 ._Calendar-module__calendarIcon__2JTLK path {
    stroke: #DD1F4A; }

._Calendar-module__disabled__HD880 {
  border-bottom: 2px solid #D7D8D9 !important; }
  ._Calendar-module__disabled__HD880 ._Calendar-module__dateParameter__1G9j_ {
    cursor: default;
    color: #D7D8D9; }
    ._Calendar-module__disabled__HD880 ._Calendar-module__dateParameter__1G9j_::-moz-placeholder {
      color: #D7D8D9; }
    ._Calendar-module__disabled__HD880 ._Calendar-module__dateParameter__1G9j_:-ms-input-placeholder {
      color: #D7D8D9; }
    ._Calendar-module__disabled__HD880 ._Calendar-module__dateParameter__1G9j_::placeholder {
      color: #D7D8D9; }
  ._Calendar-module__disabled__HD880 ._Calendar-module__calendarIcon__2JTLK {
    cursor: default; }
    ._Calendar-module__disabled__HD880 ._Calendar-module__calendarIcon__2JTLK rect, ._Calendar-module__disabled__HD880 ._Calendar-module__calendarIcon__2JTLK path {
      stroke: #D7D8D9; }

._Day-module__days__3OxZT {
  width: 100%;
  display: flex;
  flex-flow: wrap; }
  @media (max-width: 896px) {
    ._Day-module__days__3OxZT {
      padding: 1rem;
      overflow-y: auto; } }

._Day-module__days__3OxZT ._Day-module__day__2YtbT {
  font-size: 16px;
  color: #242F38;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  transition: all 100ms ease;
  width: 14.2%;
  height: 43px;
  font-weight: 500;
  letter-spacing: -0.2px; }
  @media (min-width: 897px) {
    ._Day-module__days__3OxZT ._Day-module__day__2YtbT {
      height: 54px; } }

@media (min-width: 896px) {
  ._Day-module__days__3OxZT ._Day-module__day__2YtbT:hover {
    color: white;
    background-color: #1B8DB3;
    font-weight: 600; } }

._Day-module__days__3OxZT ._Day-module__day__2YtbT:active {
  color: white;
  background-color: #1B8DB3;
  font-weight: 600; }

._Month-module__months__3F091 {
  width: 100%;
  display: flex;
  flex-flow: wrap; }
  @media (max-width: 896px) {
    ._Month-module__months__3F091 {
      justify-content: center;
      overflow-y: auto;
      padding-top: 0.9rem; } }

._Month-module__months__3F091 ._Month-module__month__3l0Wi {
  width: 33.333%;
  height: 43px;
  font-size: 16px;
  color: #242F38;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  transition: all 100ms ease;
  font-weight: 500;
  letter-spacing: -0.2px; }
  @media (min-width: 897px) {
    ._Month-module__months__3F091 ._Month-module__month__3l0Wi {
      height: 54px; } }

@media (min-width: 896px) {
  ._Month-module__months__3F091 ._Month-module__month__3l0Wi:hover {
    color: white;
    background-color: #1B8DB3;
    font-weight: 600; } }

._Month-module__months__3F091 ._Month-module__month__3l0Wi:active {
  color: white;
  background-color: #1B8DB3;
  font-weight: 600; }

._Year-module__years__2Q5jJ {
  width: calc(100% + 20px);
  display: flex;
  flex-flow: wrap;
  overflow-y: scroll;
  max-height: 331px; }
  @media (max-width: 896px) {
    ._Year-module__years__2Q5jJ {
      width: calc(100%);
      justify-content: center;
      overflow-y: auto;
      padding: 0.75rem 1.5rem; } }

._Year-module__line__1uPsD {
  width: 100%;
  margin: 8px 0;
  border-bottom: 1px solid #DDDDDD; }

._Year-module__years__2Q5jJ ._Year-module__year__NMv33 {
  width: calc(20% - 4px);
  height: 47px;
  font-size: 16px;
  color: #242F38;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  transition: all 100ms ease;
  font-weight: 500;
  letter-spacing: -0.2px; }
  @media (min-width: 897px) {
    ._Year-module__years__2Q5jJ ._Year-module__year__NMv33 {
      height: 54px; } }

@media (min-width: 896px) {
  ._Year-module__years__2Q5jJ ._Year-module__year__NMv33:hover {
    color: white;
    background-color: #1B8DB3;
    font-weight: 600; } }

._Year-module__years__2Q5jJ ._Year-module__year__NMv33:active {
  color: white;
  background-color: #1B8DB3;
  font-weight: 600; }

._ValidationMessage-module__validationMessage__1EJWm {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  color: #AFAFB1;
  margin-top: 5px;
  position: absolute;
  top: 78px;
  font-weight: 500; }

._ValidationMessage-module__empty__hrmD7 {
  display: none; }

._ValidationMessage-module__success__EIVd_ {
  color: #138057; }

._ValidationMessage-module__undefined__26Mhc {
  visibility: hidden; }

._ValidationMessage-module__error__gqdII {
  color: #DD1F4A; }

._ValidationMessage-module__warning__oT3ad {
  color: #E67417; }

._Button-module__standardButton__1DJbC {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
  outline: none !important;
  height: 56px;
  min-width: 150px;
  padding-left: 32px;
  padding-right: 32px;
  border-width: 2px;
  border-style: solid;
  border-radius: 28px;
  border-color: black;
  background-color: black;
  transition: all .2s linear; }
  ._Button-module__standardButton__1DJbC:after {
    top: -7px;
    left: -7px;
    opacity: 0;
    border-width: 2px;
    position: absolute;
    border-style: solid;
    border-radius: 36px;
    width: calc(100% + 14px);
    height: calc(100% + 14px);
    transition: all .2s linear; }
  ._Button-module__standardButton__1DJbC:focus:after {
    opacity: 1; }
  ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__primaryButton__1lAs3._Button-module__action__343KE {
    background-color: #145685;
    border-color: #145685; }
    ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__primaryButton__1lAs3._Button-module__action__343KE:hover, ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__primaryButton__1lAs3._Button-module__action__343KE:focus {
      background-color: #10446A;
      border-color: #10446A;
      transition: all .2s linear; }
    ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__primaryButton__1lAs3._Button-module__action__343KE:after {
      border-color: #10446A;
      box-shadow: 0 0 0 6px rgba(16, 68, 106, 0.1); }
  ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__primaryButton__1lAs3._Button-module__error__3Pxvi {
    background-color: #DD1F4A;
    border-color: #DD1F4A; }
    ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__primaryButton__1lAs3._Button-module__error__3Pxvi:hover, ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__primaryButton__1lAs3._Button-module__error__3Pxvi:focus {
      background-color: #991533;
      border-color: #991533;
      transition: all .2s linear; }
    ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__primaryButton__1lAs3._Button-module__error__3Pxvi:after {
      border-color: #991533;
      box-shadow: 0 0 0 6px rgba(153, 21, 51, 0.1); }
  ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__primaryButton__1lAs3._Button-module__white__1pOL3 {
    background-color: white;
    border-color: white;
    color: #10446A; }
    ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__primaryButton__1lAs3._Button-module__white__1pOL3:hover, ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__primaryButton__1lAs3._Button-module__white__1pOL3:focus {
      background-color: white;
      border-color: white;
      transition: all .2s linear; }
    ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__primaryButton__1lAs3._Button-module__white__1pOL3:after {
      border-color: white;
      box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.1); }
  ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__secondaryButton__3rNfc {
    background-color: transparent; }
    ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__secondaryButton__3rNfc._Button-module__action__343KE {
      color: #145685;
      border-color: #145685; }
      ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__secondaryButton__3rNfc._Button-module__action__343KE:hover, ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__secondaryButton__3rNfc._Button-module__action__343KE:focus {
        color: #10446A;
        border-color: #10446A;
        transition: all .2s linear; }
      ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__secondaryButton__3rNfc._Button-module__action__343KE:after {
        color: #10446A;
        border-color: #10446A;
        box-shadow: 0 0 0 6px rgba(16, 68, 106, 0.1); }
    ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__secondaryButton__3rNfc._Button-module__error__3Pxvi {
      color: #DD1F4A;
      border-color: #DD1F4A; }
      ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__secondaryButton__3rNfc._Button-module__error__3Pxvi:hover, ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__secondaryButton__3rNfc._Button-module__error__3Pxvi:focus {
        color: #991533;
        border-color: #991533;
        transition: all .2s linear; }
      ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__secondaryButton__3rNfc._Button-module__error__3Pxvi:after {
        color: #991533;
        border-color: #991533;
        box-shadow: 0 0 0 6px rgba(153, 21, 51, 0.1); }
    ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__secondaryButton__3rNfc._Button-module__white__1pOL3 {
      color: white;
      border-color: white; }
      ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__secondaryButton__3rNfc._Button-module__white__1pOL3:hover, ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__secondaryButton__3rNfc._Button-module__white__1pOL3:focus {
        color: white;
        border-color: white;
        transition: all .2s linear; }
      ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__secondaryButton__3rNfc._Button-module__white__1pOL3:after {
        color: white;
        border-color: white;
        box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.1); }
    ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__secondaryButton__3rNfc._Button-module__whiteInvert__2OwYw {
      color: #145685 !important;
      background-color: white !important;
      border-color: #145685 !important; }
      ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__secondaryButton__3rNfc._Button-module__whiteInvert__2OwYw:hover, ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__secondaryButton__3rNfc._Button-module__whiteInvert__2OwYw:focus {
        background-color: #10446A;
        border-color: #10446A;
        transition: all .2s linear; }
      ._Button-module__standardButton__1DJbC:not(._Button-module__disabled__1BllH)._Button-module__secondaryButton__3rNfc._Button-module__whiteInvert__2OwYw:after {
        border-color: #10446A;
        box-shadow: unset;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        top: 2px;
        left: 2px; }
  ._Button-module__standardButton__1DJbC ._Button-module__icon__1CkmD {
    margin-left: 25px;
    display: flex;
    justify-content: center;
    align-items: center; }
  ._Button-module__standardButton__1DJbC._Button-module__full__1HyIV {
    width: 100%; }
  ._Button-module__standardButton__1DJbC._Button-module__auto__2unV0 {
    width: auto; }
  ._Button-module__standardButton__1DJbC._Button-module__medium__3MpNV {
    width: auto;
    font-size: 14px; }
  ._Button-module__standardButton__1DJbC._Button-module__small__VlwKh {
    width: auto;
    font-size: 12px; }
  ._Button-module__standardButton__1DJbC._Button-module__extraSmall__7hpIm {
    width: auto;
    font-size: 10px; }
  ._Button-module__standardButton__1DJbC._Button-module__large__2JMDl {
    width: auto;
    font-size: 16px; }
  ._Button-module__standardButton__1DJbC._Button-module__extraLarge__1BITI {
    width: auto;
    font-size: 18px; }
  ._Button-module__standardButton__1DJbC._Button-module__icon__1CkmD {
    justify-content: space-between; }
  ._Button-module__standardButton__1DJbC._Button-module__disabled__1BllH {
    cursor: not-allowed;
    color: #D7D8D9;
    border-color: #E3E5E6;
    background-color: #E3E5E6;
    position: relative; }
    ._Button-module__standardButton__1DJbC._Button-module__disabled__1BllH ._Button-module__disableLabel__2Rkf3 {
      position: absolute;
      left: 0;
      top: calc(100% + 15px);
      text-align: left;
      font-weight: 400;
      text-transform: none; }

._Button-module__textButton__37IAn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  padding: 0;
  cursor: pointer;
  outline: none !important;
  width: auto;
  border: none;
  background: none;
  transition: all .2s linear; }
  ._Button-module__textButton__37IAn:after {
    left: -8px;
    top: -4px;
    opacity: 0;
    position: absolute;
    border-radius: 4px;
    border-width: 2px;
    border-style: solid;
    width: calc(100% + 16px);
    height: calc(100% + 8px);
    transition: opacity .2s linear; }
  ._Button-module__textButton__37IAn:focus:after {
    opacity: 1; }
  ._Button-module__textButton__37IAn._Button-module__action__343KE {
    color: #145685; }
    ._Button-module__textButton__37IAn._Button-module__action__343KE:hover, ._Button-module__textButton__37IAn._Button-module__action__343KE:focus {
      color: #10446A; }
    ._Button-module__textButton__37IAn._Button-module__action__343KE:after {
      border-color: #10446A;
      box-shadow: 0 0 0 4px rgba(16, 68, 106, 0.1); }
  ._Button-module__textButton__37IAn._Button-module__error__3Pxvi {
    color: #DD1F4A; }
    ._Button-module__textButton__37IAn._Button-module__error__3Pxvi:hover, ._Button-module__textButton__37IAn._Button-module__error__3Pxvi:focus {
      color: #991533; }
    ._Button-module__textButton__37IAn._Button-module__error__3Pxvi:after {
      border-color: #991533;
      box-shadow: 0 0 0 4px rgba(153, 21, 51, 0.1); }
  ._Button-module__textButton__37IAn._Button-module__medium__3MpNV {
    width: auto;
    font-size: 14px; }
  ._Button-module__textButton__37IAn._Button-module__small__VlwKh {
    width: auto;
    font-size: 12px; }
  ._Button-module__textButton__37IAn._Button-module__extraSmall__7hpIm {
    width: auto;
    font-size: 10px; }
  ._Button-module__textButton__37IAn._Button-module__large__2JMDl {
    width: auto;
    font-size: 16px; }
  ._Button-module__textButton__37IAn._Button-module__extraLarge__1BITI {
    width: auto;
    font-size: 18px; }

._Button-module__textButton__37IAn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  padding: 0;
  cursor: pointer;
  outline: none !important;
  width: auto;
  border: none;
  background: none;
  transition: all .2s linear; }
  ._Button-module__textButton__37IAn:after {
    left: -8px;
    top: -4px;
    opacity: 0;
    position: absolute;
    border-radius: 4px;
    border-width: 2px;
    border-style: solid;
    width: calc(100% + 16px);
    height: calc(100% + 8px);
    transition: opacity .2s linear; }
  ._Button-module__textButton__37IAn:focus:after {
    opacity: 1; }
  ._Button-module__textButton__37IAn._Button-module__action__343KE {
    color: #145685; }
    ._Button-module__textButton__37IAn._Button-module__action__343KE:hover, ._Button-module__textButton__37IAn._Button-module__action__343KE:focus {
      color: #10446A; }
    ._Button-module__textButton__37IAn._Button-module__action__343KE:after {
      border-color: #10446A;
      box-shadow: 0 0 0 4px rgba(16, 68, 106, 0.1); }
  ._Button-module__textButton__37IAn._Button-module__disabled__1BllH {
    cursor: not-allowed; }
    ._Button-module__textButton__37IAn._Button-module__disabled__1BllH, ._Button-module__textButton__37IAn._Button-module__disabled__1BllH:hover, ._Button-module__textButton__37IAn._Button-module__disabled__1BllH:focus {
      color: #D7D8D9 !important; }

._Button-module__button__2X_Ir._Button-module__width-1__dXm4R {
  width: 156px;
  min-width: 156px;
  max-width: 156px; }

._Button-module__button__2X_Ir._Button-module__width-2__3_wlg {
  width: 183px;
  min-width: 183px;
  max-width: 183px; }

._Button-module__button__2X_Ir._Button-module__width-3__vi95U {
  width: 200px;
  min-width: 200px;
  max-width: 200px; }

._Button-module__button__2X_Ir._Button-module__width-4__3zzwG {
  width: 240px;
  min-width: 240px;
  max-width: 240px; }

._Button-module__button__2X_Ir._Button-module__width-5__3fjcw {
  width: 136px;
  min-width: 136px;
  max-width: 136px; }

._Button-module__stickyButton__3Jow_ {
  height: 56px;
  width: 56px;
  border-radius: 28px;
  box-shadow: 0 6px 20px 0 rgba(36, 47, 56, 0.5);
  outline: none;
  border: none;
  background-color: #145685;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer; }
  ._Button-module__stickyButton__3Jow_ * {
    transition: all .2s linear; }
  ._Button-module__stickyButton__3Jow_:hover, ._Button-module__stickyButton__3Jow_:focus {
    background-color: #10446A;
    box-shadow: 0 4px 10px -8px rgba(38, 50, 56, 0.6); }

._StatisticsCircle-module__circleWidgetContainer__1AgZb {
  text-align: center; }
  ._StatisticsCircle-module__circleWidgetContainer__1AgZb * {
    transition: all .3s ease-in-out; }
  ._StatisticsCircle-module__circleWidgetContainer__1AgZb ._StatisticsCircle-module__widgetHeader__12SYl {
    color: #969899;
    margin-bottom: 2rem;
    font-size: 1.4rem; }
  ._StatisticsCircle-module__circleWidgetContainer__1AgZb ._StatisticsCircle-module__pink__2sZYG {
    background: #dd7d92; }
    ._StatisticsCircle-module__circleWidgetContainer__1AgZb ._StatisticsCircle-module__pink__2sZYG:active {
      box-shadow: 0 0 0 0.8rem rgba(206, 55, 77, 0.2); }
  ._StatisticsCircle-module__circleWidgetContainer__1AgZb ._StatisticsCircle-module__green__2MfAX {
    background: #bdce82; }
    ._StatisticsCircle-module__circleWidgetContainer__1AgZb ._StatisticsCircle-module__green__2MfAX:active {
      box-shadow: 0 0 0 0.8rem rgba(189, 206, 130, 0.33); }
  ._StatisticsCircle-module__circleWidgetContainer__1AgZb ._StatisticsCircle-module__blue__1DpQO {
    background: #78a0b2; }
    ._StatisticsCircle-module__circleWidgetContainer__1AgZb ._StatisticsCircle-module__blue__1DpQO:active {
      box-shadow: 0 0 0 0.8rem rgba(120, 160, 178, 0.33); }
  ._StatisticsCircle-module__circleWidgetContainer__1AgZb ._StatisticsCircle-module__grey__33GS7 {
    background: #dfdfe0;
    color: #dfdfe0; }
    ._StatisticsCircle-module__circleWidgetContainer__1AgZb ._StatisticsCircle-module__grey__33GS7 ._StatisticsCircle-module__inner-circle__2ELeI {
      box-shadow: none !important; }
  ._StatisticsCircle-module__circleWidgetContainer__1AgZb a {
    color: #000000; }
    ._StatisticsCircle-module__circleWidgetContainer__1AgZb a:hover {
      color: #000000; }
  ._StatisticsCircle-module__circleWidgetContainer__1AgZb ._StatisticsCircle-module__circleWidget__1-ZoZ {
    border-radius: 50%;
    height: 16rem;
    width: 16rem;
    position: relative;
    margin: 0 auto;
    /*
     Child elements with absolute positioning will be
     positioned relative to this div
    */ }
    @media (max-width: 768px) {
      ._StatisticsCircle-module__circleWidgetContainer__1AgZb ._StatisticsCircle-module__circleWidget__1-ZoZ {
        width: 14.4rem;
        height: 14.4rem; } }
    ._StatisticsCircle-module__circleWidgetContainer__1AgZb ._StatisticsCircle-module__circleWidget__1-ZoZ ._StatisticsCircle-module__innerCircle__3qotQ {
      position: absolute;
      background: #fbfbfb;
      border-radius: 50%;
      height: 12rem;
      width: 12rem;
      /*
       Put top edge and left edge in the center
      */
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      /*
       Offset the position correctly with
       minus half of the width and minus half of the height
      */
      box-shadow: 0 1rem .6rem -.6rem #777; }
      @media (max-width: 768px) {
        ._StatisticsCircle-module__circleWidgetContainer__1AgZb ._StatisticsCircle-module__circleWidget__1-ZoZ ._StatisticsCircle-module__innerCircle__3qotQ {
          width: 10.8rem;
          height: 10.8rem; } }
      ._StatisticsCircle-module__circleWidgetContainer__1AgZb ._StatisticsCircle-module__circleWidget__1-ZoZ ._StatisticsCircle-module__innerCircle__3qotQ ._StatisticsCircle-module__digit__2ZHGY {
        position: absolute;
        text-align: center;
        width: 100%;
        vertical-align: text-bottom;
        font-size: 4rem;
        font-weight: bold;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
        @media (max-width: 768px) {
          ._StatisticsCircle-module__circleWidgetContainer__1AgZb ._StatisticsCircle-module__circleWidget__1-ZoZ ._StatisticsCircle-module__innerCircle__3qotQ ._StatisticsCircle-module__digit__2ZHGY {
            font-size: 3.6rem; } }
      ._StatisticsCircle-module__circleWidgetContainer__1AgZb ._StatisticsCircle-module__circleWidget__1-ZoZ ._StatisticsCircle-module__innerCircle__3qotQ:hover {
        transform: translate(-50%, -50%) scale(1.1);
        box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.04), 0 0.1rem 0.6rem 0 rgba(0, 0, 0, 0.08), 0.1rem 0.3rem 1rem 0 rgba(0, 0, 0, 0.16), 0.2rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.16), 0.5rem 0.9rem 3.6rem 0 rgba(0, 0, 0, 0.2), 1rem 2.2rem 4.3rem 0 rgba(0, 0, 0, 0.3); }
    ._StatisticsCircle-module__circleWidgetContainer__1AgZb ._StatisticsCircle-module__circleWidget__1-ZoZ:active:before {
      position: absolute;
      display: block;
      content: "";
      border: 0.213rem solid #fff;
      height: calc(100% - .426rem);
      width: calc(100% - .426rem);
      margin-top: 0.213rem;
      margin-left: 0.213rem;
      border-radius: 50%; }
  ._StatisticsCircle-module__circleWidgetContainer__1AgZb._StatisticsCircle-module__disabled__eLQHf {
    cursor: not-allowed; }
    ._StatisticsCircle-module__circleWidgetContainer__1AgZb._StatisticsCircle-module__disabled__eLQHf * {
      pointer-events: none; }
    ._StatisticsCircle-module__circleWidgetContainer__1AgZb._StatisticsCircle-module__disabled__eLQHf ._StatisticsCircle-module__circleWidget__1-ZoZ {
      background-color: #E3E5E6; }
      ._StatisticsCircle-module__circleWidgetContainer__1AgZb._StatisticsCircle-module__disabled__eLQHf ._StatisticsCircle-module__circleWidget__1-ZoZ ._StatisticsCircle-module__digit__2ZHGY {
        color: #D7D8D9; }

._Sticky-module__sticky__AVknr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2rem;
  border-radius: .8rem;
  background-color: #145685;
  box-shadow: 0 0.8rem 2rem 0 rgba(36, 47, 56, 0.5); }
  ._Sticky-module__sticky__AVknr:hover {
    background-color: #10446A; }

._Sticky-module__stickyContent__2BCXn {
  width: auto; }

._TriggerPopup-module__triggerPopup__2Ywqi {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 16px;
  min-height: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  padding: 5px 10px; }
  ._TriggerPopup-module__triggerPopup__2Ywqi, ._TriggerPopup-module__triggerPopup__2Ywqi * {
    transition: all .3s linear;
    cursor: pointer; }

._TriggerPopup-module__contentWrapper__1hY2- {
  height: auto;
  width: auto;
  position: absolute;
  top: calc(100% + 12.5px);
  left: auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 27px 100px -20px rgba(38, 50, 56, 0.2), 0 4px 15px -10px rgba(38, 50, 56, 0.2), 0 9px 30px -15px rgba(38, 50, 56, 0.2), 0 18px 60px -30px rgba(38, 50, 56, 0.2);
  padding: 25px;
  z-index: 10;
  color: #242F38;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px; }
  ._TriggerPopup-module__contentWrapper__1hY2-::before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #ffffff transparent;
    top: -30px;
    left: calc(50% - 10px);
    padding-top: 20px; }

._TriggerPopup-module__triggerPopup__2Ywqi._TriggerPopup-module__left__2k1pI ._TriggerPopup-module__contentWrapper__1hY2- {
  right: calc(100% + 20px);
  left: auto;
  top: unset; }
  ._TriggerPopup-module__triggerPopup__2Ywqi._TriggerPopup-module__left__2k1pI ._TriggerPopup-module__contentWrapper__1hY2-::before {
    top: 30px;
    right: -12px;
    left: unset;
    padding-top: 0;
    transform: rotate(90deg); }

._TriggerPopup-module__activeColorIcon__3b3AP path {
  fill: #145685; }

._TriggerPopup-module__defaultColorIcon__3gWWY path {
  fill: #969899; }

._TriggerPopup-module__defaultColorIcon__3gWWY:hover path {
  fill: #1B8DB3; }

._ExpandableRow-module__expandableRow__1MUS1 {
  width: 100%;
  border-radius: .8rem;
  background-color: white;
  box-shadow: 0 1.8rem 6rem -3rem rgba(1, 50, 62, 0.2);
  position: relative;
  outline: none;
  transition: box-shadow .2s linear; }
  @media (max-width: 768px) {
    ._ExpandableRow-module__expandableRow__1MUS1 {
      border-radius: 0; } }
  ._ExpandableRow-module__expandableRow__1MUS1._ExpandableRow-module__expanded__3PJjF:not(._ExpandableRow-module__expandedError__2EhBk) ._ExpandableRow-module__expandableRowArrow__3_imU {
    transform: translateY(-50%) rotate(180deg); }
    @media (max-width: 768px) {
      ._ExpandableRow-module__expandableRow__1MUS1._ExpandableRow-module__expanded__3PJjF:not(._ExpandableRow-module__expandedError__2EhBk) ._ExpandableRow-module__expandableRowArrow__3_imU {
        transform: rotate(180deg); } }
  ._ExpandableRow-module__expandableRow__1MUS1._ExpandableRow-module__expanded__3PJjF:not(._ExpandableRow-module__expandedError__2EhBk) ._ExpandableRow-module__more__vxHdA {
    max-height: 750px;
    transition: max-height .3s linear; }
  ._ExpandableRow-module__expandableRow__1MUS1._ExpandableRow-module__expanded__3PJjF:not(._ExpandableRow-module__expandedError__2EhBk) ._ExpandableRow-module__top__3BUQY {
    border-radius: 0;
    border-bottom: 0.1rem solid #E3E5E6; }
  ._ExpandableRow-module__expandableRow__1MUS1._ExpandableRow-module__expanded__3PJjF._ExpandableRow-module__expandedError__2EhBk ._ExpandableRow-module__errorMoreContent__3I7lG {
    max-height: 750px;
    transition: max-height .3s linear; }
  ._ExpandableRow-module__expandableRow__1MUS1._ExpandableRow-module__expanded__3PJjF._ExpandableRow-module__expandedError__2EhBk ._ExpandableRow-module__expandableRowStatusBar__16PSw {
    border-radius: 0; }
  ._ExpandableRow-module__expandableRow__1MUS1:after {
    opacity: 0;
    top: -.6rem;
    left: -.6rem;
    position: absolute;
    border-radius: 1.2rem;
    pointer-events: none;
    width: calc(100% + 1.2rem);
    height: calc(100% + 1.2rem);
    transition: opacity .2s linear;
    box-shadow: 0 0 0 0.6rem rgba(16, 68, 106, 0.1);
    border: 2px solid #145685;
    z-index: 2; }
    @media (max-width: 768px) {
      ._ExpandableRow-module__expandableRow__1MUS1:after {
        border-radius: 0; } }
  ._ExpandableRow-module__expandableRow__1MUS1:focus:after {
    opacity: 1; }
  ._ExpandableRow-module__expandableRow__1MUS1:hover {
    box-shadow: 0 2.7rem 10rem -2rem rgba(38, 50, 56, 0.2), 0 0.4rem 1.5rem -1rem rgba(38, 50, 56, 0.2), 0 0.9rem 3rem -1.5rem rgba(38, 50, 56, 0.2), 0 1.8rem 6rem -3rem rgba(38, 50, 56, 0.2); }
  ._ExpandableRow-module__expandableRow__1MUS1:active {
    box-shadow: 0 0.4rem 1rem -0.8rem rgba(38, 50, 56, 0.6); }
  ._ExpandableRow-module__expandableRow__1MUS1._ExpandableRow-module__expandable__3rQha {
    cursor: pointer; }
    ._ExpandableRow-module__expandableRow__1MUS1._ExpandableRow-module__expandable__3rQha ._ExpandableRow-module__content__1lnGs {
      margin-right: 4.5rem; }
      @media (max-width: 768px) {
        ._ExpandableRow-module__expandableRow__1MUS1._ExpandableRow-module__expandable__3rQha ._ExpandableRow-module__content__1lnGs {
          margin-right: unset; } }
  ._ExpandableRow-module__expandableRow__1MUS1._ExpandableRow-module__disabled__JFdKq {
    cursor: default;
    opacity: .5; }
  ._ExpandableRow-module__expandableRow__1MUS1._ExpandableRow-module__groupPositionTop__hj3TG {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0.1rem solid #E3E5E6; }
  ._ExpandableRow-module__expandableRow__1MUS1._ExpandableRow-module__groupPositionMiddle__1Nabq {
    border-radius: 0;
    border-bottom: 0.1rem solid #E3E5E6; }
  ._ExpandableRow-module__expandableRow__1MUS1._ExpandableRow-module__groupPositionBottom__2A8G2 {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

._ExpandableRow-module__expandableRowArrow__3_imU {
  position: absolute;
  top: 50%;
  right: 3rem;
  transform: translateY(-50%);
  transition: all .1s linear; }
  @media (max-width: 768px) {
    ._ExpandableRow-module__expandableRowArrow__3_imU {
      top: 2.8rem;
      right: 2.4rem;
      transform: unset; } }

._ExpandableRow-module__content__1lnGs {
  width: 100%; }

._ExpandableRow-module__top__3BUQY {
  position: relative;
  min-height: 7.2rem;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: .1rem solid white;
  border-bottom-left-radius: .8rem;
  border-bottom-right-radius: .8rem; }

._ExpandableRow-module__errorMoreContent__3I7lG {
  overflow: hidden;
  max-height: 0;
  transition: max-height .3s linear; }

._ExpandableRow-module__more__vxHdA {
  overflow: hidden;
  max-height: 0;
  transition: max-height .3s linear; }

._ExpandableRow-module__expandableRowStatusBar__16PSw {
  color: white;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 600;
  padding: .5rem 3rem .5rem 3.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  ._ExpandableRow-module__expandableRowStatusBar__16PSw._ExpandableRow-module__rounded__5ZUib {
    border-bottom-left-radius: .8rem;
    border-bottom-right-radius: .8rem; }
    @media (max-width: 768px) {
      ._ExpandableRow-module__expandableRowStatusBar__16PSw._ExpandableRow-module__rounded__5ZUib {
        border-radius: 0; } }
  ._ExpandableRow-module__expandableRowStatusBar__16PSw._ExpandableRow-module__errorExpandable__yQH3N {
    cursor: pointer; }
  ._ExpandableRow-module__expandableRowStatusBar__16PSw._ExpandableRow-module__error__2YkuF {
    background-color: #DD1F4A; }
  ._ExpandableRow-module__expandableRowStatusBar__16PSw._ExpandableRow-module__success__1H8hy {
    background-color: #138057; }
  ._ExpandableRow-module__expandableRowStatusBar__16PSw._ExpandableRow-module__warning__2df3J {
    background-color: #E67417; }
  ._ExpandableRow-module__expandableRowStatusBar__16PSw ._ExpandableRow-module__errorArrow__3UY6s {
    margin-left: 1rem; }
    ._ExpandableRow-module__expandableRowStatusBar__16PSw ._ExpandableRow-module__errorArrow__3UY6s svg path {
      stroke: white; }

._Status-module__status__2hE7X {
  display: inline-flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 24px;
  height: auto;
  color: white;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.15px;
  text-transform: uppercase;
  border: 2px solid; }
  ._Status-module__status__2hE7X._Status-module__rounded__glzk_ {
    border-radius: 100px; }
  ._Status-module__status__2hE7X._Status-module__roundedBottom__ggDtv {
    border-bottom-left-radius: .8rem;
    border-bottom-right-radius: .8rem; }
  ._Status-module__status__2hE7X._Status-module__square__27J_q {
    border-radius: unset; }
  ._Status-module__status__2hE7X._Status-module__highlight__d4CD7 {
    border-color: #1B8DB3;
    background-color: #1B8DB3; }
  ._Status-module__status__2hE7X._Status-module__lightHighlight__3Lw2z {
    border-color: #75B8CF;
    background-color: #75B8CF; }
  ._Status-module__status__2hE7X._Status-module__error__1OtxR {
    border-color: #DD1F4A;
    background-color: #DD1F4A; }
  ._Status-module__status__2hE7X._Status-module__warning__2PtwN {
    border-color: #E67417;
    background-color: #E67417; }
  ._Status-module__status__2hE7X._Status-module__verification__3TgjH {
    border-color: #138057;
    background-color: #138057; }
  ._Status-module__status__2hE7X._Status-module__green__yOzdp {
    border-color: #C4D787;
    background-color: #C4D787; }
  ._Status-module__status__2hE7X._Status-module__disabled__OwG6G {
    color: #969899;
    border-color: #EEEFF0;
    background-color: #EEEFF0; }
  ._Status-module__status__2hE7X._Status-module__black__2Zr5y {
    border-color: #242F38;
    background-color: #242F38; }
  ._Status-module__status__2hE7X._Status-module__red__3R53l {
    border-color: #d78787;
    background-color: #d78787; }
  ._Status-module__status__2hE7X._Status-module__full__17Oac {
    width: 100%; }
  ._Status-module__status__2hE7X._Status-module__auto__MpnGg {
    width: auto; }

._MutationExpandableRow-module__mutationExpandableRow__rm-Sr {
  width: 100%;
  border-radius: 0.8rem;
  background-color: white;
  box-shadow: 0 2.7rem 10rem -2rem rgba(38, 50, 56, 0.2), 0 0.4px 15rem -1rem rgba(38, 50, 56, 0.2), 0 0.9px 30px -1.5rem rgba(38, 50, 56, 0.2), 0 1.8px 6rem -3rem rgba(38, 50, 56, 0.2);
  padding: 24px 0; }
  @media (max-width: 768px) {
    ._MutationExpandableRow-module__mutationExpandableRow__rm-Sr {
      border-radius: 0; } }

._MutationExpandableRow-module__mutationExpandableRowContent__1v8Aq {
  display: flex;
  flex-direction: column;
  position: relative; }

._MutationExpandableRow-module__contentWithMessage__Qlzfz {
  padding-bottom: 0px; }

._MutationExpandableRow-module__more__2vA-V {
  transition: max-height 1s;
  max-height: 0;
  overflow: hidden; }

._MutationExpandableRow-module__moreVisible__A8NQI {
  max-height: 500px; }

._MutationExpandableRow-module__moreButtonWrapper__JEoAk {
  margin-left: 40%; }
  @media (max-width: 768px) {
    ._MutationExpandableRow-module__moreButtonWrapper__JEoAk {
      margin-left: 2.4rem; } }

@media (max-width: 768px) {
  ._MutationExpandableRow-module__moreButtonWrapper__JEoAk button {
    font-size: 1rem; } }

._MutationExpandableRow-module__message__GRiJm {
  height: 0px;
  transition: height 0.5s;
  overflow: hidden; }

._MutationExpandableRow-module__activeMessage__3T37l {
  height: 30px;
  border-bottom-right-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 0 30px; }
  @media (max-width: 768px) {
    ._MutationExpandableRow-module__activeMessage__3T37l {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; } }

._MutationExpandableRow-module__actions__rcGuX {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  justify-content: space-between;
  height: 4rem;
  align-items: center; }

._MutationExpandableRow-module__leftActions__14mkW {
  display: flex;
  flex-direction: row;
  position: relative; }

._MutationExpandableRow-module__arrowIcon__1jbpZ {
  margin-left: 0.8rem;
  transition: transform 0.3s; }

._MutationExpandableRow-module__arrowIconActive__fSrPa {
  transform: rotate(180deg); }

._MutationExpandableRow-module__arrowIconActive__fSrPa path {
  stroke: #1b8db3; }
  @media (max-width: 768px) {
    ._MutationExpandableRow-module__arrowIconActive__fSrPa path {
      stroke: #145685 !important; } }

._MutationExpandableRow-module__deleteButton__vpube {
  margin-left: 3.2rem; }
  @media (max-width: 768px) {
    ._MutationExpandableRow-module__deleteButton__vpube {
      margin-left: 2rem; } }

._MutationExpandableRow-module__popupWrapper__d7g9m {
  position: absolute;
  left: 136px;
  overflow: hidden;
  top: -15px; }
  @media (max-width: 768px) {
    ._MutationExpandableRow-module__popupWrapper__d7g9m {
      left: 115px; } }

._MutationExpandableRow-module__popupWrapperActive__3xHq6 {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2.7rem 10rem -2rem rgba(38, 50, 56, 0.2), 0 0.4px 15rem -1rem rgba(38, 50, 56, 0.2), 0 0.9px 30px -1.5rem rgba(38, 50, 56, 0.2), 0 1.8px 6rem -3rem rgba(38, 50, 56, 0.2); }
  @media (max-width: 768px) {
    ._MutationExpandableRow-module__popupWrapperActive__3xHq6 {
      border-radius: 0px;
      box-shadow: none;
      z-index: 2; } }

._MutationExpandableRow-module__popupButton__JWmNd {
  margin: 14px 0 14px 17px; }
  @media (max-width: 768px) {
    ._MutationExpandableRow-module__popupButton__JWmNd {
      margin: 0;
      padding: 14px 11px 16px 11px; } }

._MutationExpandableRow-module__popupButtonActive__1dbua {
  color: #1b8db3 !important; }
  @media (max-width: 768px) {
    ._MutationExpandableRow-module__popupButtonActive__1dbua {
      color: #145685 !important;
      box-shadow: 0 2.7rem 10rem -2rem rgba(38, 50, 56, 0.2), 0 0.4px 15rem -1rem rgba(38, 50, 56, 0.2), 0 0.9px 30px -1.5rem rgba(38, 50, 56, 0.2), 0 1.8px 6rem -3rem rgba(38, 50, 56, 0.2); } }

._MutationExpandableRow-module__popupButton__JWmNd::after {
  border: none !important;
  box-shadow: none !important; }

._MutationExpandableRow-module__popupMenu__1p8__ {
  background-color: #ffffff;
  border-top: none;
  max-height: 0;
  transition: max-height 0.3s linear;
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; }
  @media (max-width: 480px) {
    ._MutationExpandableRow-module__popupMenu__1p8__ {
      display: none; } }

._MutationExpandableRow-module__popupMenuActive__CEyKa {
  border-top: 1px solid #eeeff0;
  max-height: 200px; }
  @media (max-width: 480px) {
    ._MutationExpandableRow-module__popupMenuActive__CEyKa {
      border-top: none;
      display: none; } }

._MutationExpandableRow-module__overViewButton__2Xmg3 {
  margin-right: 3.2rem; }
  @media (max-width: 768px) {
    ._MutationExpandableRow-module__overViewButton__2Xmg3 {
      margin-right: 2rem; } }

._MutationExpandableRow-module__success__1oWrs {
  background-color: #138057; }

._MutationExpandableRow-module__error__2epxe {
  background-color: #DD1F4A; }

._MutationExpandableRow-module__warning__6SAvQ {
  background-color: #E67417; }

._MutationExpandableRow-module__popupContentWrapperMobile__rFNCP {
  display: none;
  position: absolute;
  width: 100vw;
  top: 27px;
  z-index: 1;
  justify-content: center; }
  @media (max-width: 480px) {
    ._MutationExpandableRow-module__popupContentWrapperMobile__rFNCP {
      display: flex; } }

._MutationExpandableRow-module__popupMenuMobile__3y5eV {
  background-color: #ffff;
  max-height: 0;
  overflow-y: hidden;
  transition: max-height 0.5s linear;
  box-shadow: 0 2.7rem 10rem -2rem rgba(38, 50, 56, 0.2), 0 0.4px 15rem -1rem rgba(38, 50, 56, 0.2), 0 0.9px 30px -1.5rem rgba(38, 50, 56, 0.2), 0 1.8px 6rem -3rem rgba(38, 50, 56, 0.2); }

._MutationExpandableRow-module__popupMenuActiveMobile__26lyb {
  max-height: 300px; }

._Card-module__card__2R8Y7 {
  position: relative;
  max-height: 10.6rem;
  width: 100%;
  padding: 2.4rem;
  outline: none;
  font-size: 1.6rem;
  margin-bottom: 2.4rem;
  border-radius: .8rem;
  background-color: white;
  box-shadow: 0 1.8rem 6rem -3rem rgba(38, 50, 56, 0.2);
  transition: all .1s linear; }
  @media (max-width: 768px) {
    ._Card-module__card__2R8Y7 {
      border-radius: 0; } }
  ._Card-module__card__2R8Y7:hover {
    box-shadow: 0 2.7rem 10rem -2rem rgba(38, 50, 56, 0.2), 0 0.4rem 1.5rem -1rem rgba(38, 50, 56, 0.2), 0 0.9rem 3rem -1.5rem rgba(38, 50, 56, 0.2), 0 1.8rem 6rem -3rem rgba(38, 50, 56, 0.2); }
  ._Card-module__card__2R8Y7:focus:after {
    opacity: 1; }
  ._Card-module__card__2R8Y7:active {
    box-shadow: 0 0.4rem 1rem -0.8rem rgba(38, 50, 56, 0.6); }
    ._Card-module__card__2R8Y7:active:after {
      opacity: 0; }
  ._Card-module__card__2R8Y7:after {
    opacity: 0;
    top: -.6rem;
    left: -.6rem;
    position: absolute;
    border-radius: 1.2rem;
    pointer-events: none;
    width: calc(100% + 1.2rem);
    height: calc(100% + 1.2rem);
    transition: opacity .2s linear;
    border: 0.2rem solid #145685;
    box-shadow: 0 0 0 6px rgba(16, 68, 106, 0.1); }

._Card-module__cardTop__3dogN {
  display: flex;
  margin-bottom: 1.2rem;
  align-items: center;
  justify-content: space-between; }
  ._Card-module__cardTop__3dogN button:first-child:not(:only-child) {
    margin-right: 1.6rem; }
  ._Card-module__cardTop__3dogN b + div {
    margin-left: 1.6rem; }

._Card-module__cardBottom__2Ji9N {
  display: flex; }
  ._Card-module__cardBottom__2Ji9N span {
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  ._Card-module__cardBottom__2Ji9N span:nth-child(2) {
    margin-left: 1.2rem; }

._Card-module__cardIcons__3Q0pL {
  display: flex; }
  ._Card-module__cardIcons__3Q0pL ._Card-module__pdfIcon__M7UuI {
    height: 2.4rem; }
  ._Card-module__cardIcons__3Q0pL ._Card-module__editIcon__1SenC {
    height: 2.2rem; }

._Blank-module__blank__2YNtX {
  display: flex;
  align-items: center;
  position: relative;
  height: 9.6rem;
  border: none;
  border-radius: .8rem;
  background-color: #E8EAEB; }
  @media (max-width: 768px) {
    ._Blank-module__blank__2YNtX {
      height: 15rem;
      border-radius: 0; } }

._Blank-module__blankText__32-3c {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #969899;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 2.2rem;
  white-space: nowrap; }
  @media (max-width: 768px) {
    ._Blank-module__blankText__32-3c {
      top: 4rem;
      transform: translateX(-50%); } }

._Blank-module__blankButton__2J1wP {
  position: absolute;
  height: 1.65rem;
  top: 50%;
  right: 32px;
  transform: translateY(-50%); }
  @media (max-width: 768px) {
    ._Blank-module__blankButton__2J1wP {
      bottom: 4rem;
      left: 50%;
      top: unset;
      transform: translateX(-50%); } }

._Blank-module__blank__2YNtX._Blank-module__noButton__139Kc ._Blank-module__blankText__32-3c {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

._Steps-module__steps__fW4Qq {
  display: flex;
  align-content: center; }
  @media (max-width: 768px) {
    ._Steps-module__steps__fW4Qq {
      width: 100%;
      justify-content: space-between; } }

._Steps-module__step__3pBf_ {
  flex: 1;
  display: flex;
  color: #969899;
  margin-left: 4rem;
  font-size: 1.2rem;
  font-weight: bold;
  align-items: center;
  line-height: 1.8rem;
  letter-spacing: -0.1rem;
  text-transform: uppercase; }
  @media (max-width: 768px) {
    ._Steps-module__step__3pBf_ {
      max-width: 2.4rem;
      max-height: 2.4rem; } }
  ._Steps-module__step__3pBf_ ._Steps-module__stepIcon__34Sae {
    width: 2.4rem;
    height: 2.4rem;
    text-align: center;
    border-radius: 50%;
    margin-right: 1rem;
    line-height: 2rem;
    letter-spacing: normal;
    border: 0.2rem solid #969899; }
    ._Steps-module__step__3pBf_ ._Steps-module__stepIcon__34Sae svg {
      margin-bottom: .1rem; }
    @media (max-width: 768px) {
      ._Steps-module__step__3pBf_ ._Steps-module__stepIcon__34Sae {
        margin-right: 0; } }
  ._Steps-module__step__3pBf_._Steps-module__current__lIrjF {
    color: #1B8DB3; }
    ._Steps-module__step__3pBf_._Steps-module__current__lIrjF ._Steps-module__stepIcon__34Sae {
      color: white;
      border-color: #1B8DB3;
      background-color: #1B8DB3; }
  ._Steps-module__step__3pBf_._Steps-module__completed__1ckPq {
    color: #242F38; }
    ._Steps-module__step__3pBf_._Steps-module__completed__1ckPq ._Steps-module__stepIcon__34Sae {
      color: white;
      border-color: #242F38;
      background-color: #242F38; }
  @media (max-width: 768px) {
    ._Steps-module__step__3pBf_ ._Steps-module__stepText__37vQE {
      display: none; } }
  @media (max-width: 768px) {
    ._Steps-module__step__3pBf_ {
      margin: 0; } }

._Steps-module__dotsContainer__2COw3 {
  display: none;
  flex: 1;
  align-items: center;
  justify-content: space-evenly; }
  @media (max-width: 768px) {
    ._Steps-module__dotsContainer__2COw3 {
      display: flex; } }

._Steps-module__dot__3ABWC {
  width: 2px;
  height: 2px;
  background-color: #969899; }

._StickyActions-module__stickyActions__oiIWd {
  position: relative;
  list-style: none;
  padding: 20px 20px 50px 20px;
  background-color: white;
  box-shadow: 0 8px 30px 0 rgba(1, 50, 62, 0.2); }
  ._StickyActions-module__stickyActions__oiIWd:before {
    content: "";
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    height: 4px;
    width: 40px;
    border-radius: 2px;
    background-color: #E3E5E6; }

._StickyActions-module__stickyActionItem__2vgQk {
  display: flex;
  align-items: center;
  height: 72px;
  border-bottom: 1px solid #EEEFF0; }

._StickyActions-module__stickyActionButton__3rHiF {
  position: absolute;
  right: 20px;
  top: -76px;
  height: 56px;
  width: 56px;
  outline: none;
  border: none;
  border-radius: 28px;
  background-color: #145685;
  box-shadow: 0 6px 20px 0 rgba(36, 47, 56, 0.5); }

._Tooltip-module__helpIcon__3Hlf9 {
  margin-left: 8px; }

._Tooltip-module__sharedTooltip__2lVnR {
  max-width: 211px;
  position: absolute;
  background-color: #242f38;
  padding: 16px;
  border-radius: 0.8rem;
  bottom: 35px;
  transition: opacity 0.5s; }

._Tooltip-module__activeHelpIcon__14nLe g * {
  stroke: #1b8db3; }

._Tooltip-module__deActiveHelpIcon__2wWS0 g * {
  stroke: #ffff; }
